<template>
  <div style="text-align: center">
    <b-card>
      <h1 style="text-decoration: underline;">
        <b>welcome to the generate pdf</b>
      </h1>
      <br>
      <b-button
        id="mr"
        variant="primary"
        type="filled"
        icon="arrow_downward"
        :disabled="disabled_MR"
        style="display: inline"
        @click="setPdf('mr')"
      >{{ disabled_MR === true ? "กรุณารอสักครู่" : "MR " + MRDoc.length + " IMAGES" }}
      </b-button>
      <br>
      <br>
      <b-button
        variant="danger"
        type="filled"
        icon="arrow_downward"
        style="display: inline"
        :disabled="disabled_IPD"
        @click="setPdf('ipd')"
      >{{ disabled_IPD === true ? "กรุณารอสักครู่" : "IPD " + keepClinicIPDAmount + " IMAGES" }}</b-button>
      <br>
    </b-card>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { jsPDF } from 'jspdf'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      imageUrls_MR: [],
      imageUrls_IPD: [],
      GetImage: [],
      MRDoc: [],
      offsetIPD: [],
      keepClinicIPDAmount: null,
      disabled_MR: null,
      disabled_IPD: null,
      ImageIPD: [],
      keepIPD_base64: [],
      testObgOffset: [
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        ['10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
        ['20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
        ['30', '31', '32', '33', '34', '35', '36', '37', '38', '39'],
        ['40', '41', '42', '43', '44', '45', '46', '47', '48', '49'],
        ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
        ['60', '61', '62', '63', '64', '65', '66', '67', '68', '69'],
        ['70', '71', '72', '73', '74', '75', '76', '77', '78', '79'],
        ['80', '81', '82', '83', '84', '85', '86', '87', '88', '89'],
        ['90', '91', '92', '93', '94', '95', '96', '97', '98', '99'],
        ['100', '101', '102', '103', '104', '105', '106', '107', '108', '109'],
        ['110', '111', '112', '113', '114', '115', '116', '117', '118', '119'],
        ['120', '121', '122', '123', '124', '125', '126', '127', '128', '129'],
        ['130', '131', '132', '133', '134', '135', '136', '137', '138', '139'],
        ['140', '141', '142', '143', '144', '145', '146', '147', '148', '149'],
        ['150', '151', '152', '153', '154', '155', '156', '157', '158', '159'],
        ['160', '161', '162', '163', '164', '165', '166', '167', '168', '169'],
        ['180', '181', '182', '183', '184', '185', '186', '187', '188', '189', '190'],
        ['190', '191', '192', '193', '194', '195', '196', '197', '198', '199', '200'],
      ],
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.$http({
      url: 'http://hieapi.local/api/Image/ClinicIPDAmount',
      method: 'post',
      data: {
        hn: 575946,
        clinic: '2021-05-04',
      },
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(response => {
      this.keepClinicIPDAmount = response.data
    })
    await this.$http({
      url: 'http://hieapi.local/api/HIE/MRDoc/0529550',
      method: 'get',
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    }).then(response => {
      this.MRDoc = response.data
    })
    await this.cal_ipd_ofset()
  },
  methods: {
    async setPdf(x) {
      if (x === 'mr') {
        this.disabled_MR = true
        await this.set_base64()
        await this.set_Pdf_MR()
        await this.savePdf(this.imageUrls_MR, x)
      } else {
        this.disabled_IPD = true
        await this.set_Pdf_IPD(x)
        // await this.savePdf(this.keepIPD_base64)
      }
    },
    cal_ipd_ofset() {
      let i = 0
      for (i; i < Math.ceil(Math.ceil(this.keepClinicIPDAmount / 21) / 10); i += 1) {
        let index = 0
        this.offsetIPD.push([])
        for (index; index < 10; index += 1) {
          this.offsetIPD[i][index] = 21 * this.testObgOffset[i][index]
        }
      }
      console.log('🚀', this.offsetIPD)
    },
    async set_base64() {
      let index = 0
      for (index; index < this.MRDoc.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        await this.$http({
          url: 'http://hieapi.local/api/Image/GetImage',
          method: 'post',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          data: {
            Grp: '',
            IMage: this.MRDoc[index].nameImage,
            Type: 'OPD',
            hn: 529550,
          },
        // eslint-disable-next-line no-loop-func
        }).then(response => {
          this.GetImage[index] = response.data.imageBase64
        })
      }
    },
    set() {
      let index = 0
      for (index; index < this.MRDoc.length; index += 1) {
        this.imageUrls_MR[index] = `data:image/png;base64,${this.GetImage[index]}`
      }
    },
    set_Pdf_MR() {
      let index = 0
      for (index; index < this.GetImage.length; index += 1) {
        this.imageUrls_MR[index] = `data:image/png;base64,${this.GetImage[index]}`
      }
    },
    async set_Pdf_IPD(typeFile) {
      let index = 0
      console.log(this.offsetIPD.length)
      for (index; index < this.offsetIPD.length; index += 1) {
        let i = 0
        for (i; i < 10; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await this.$http({
            url: 'http://hieapi.local/api/Image/ImageIPD',
            method: 'post',
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            data: {
              Clinic: '2021-05-04',
              Month: 0,
              Offset: this.offsetIPD[index][i],
              // Offset: this.offsetIPD[index],
              Year: 66,
              hn: 575946,
            },
          // eslint-disable-next-line no-loop-func
          }).then(response => {
            this.ImageIPD.push(response.data.medicalRecord)
            this.testKepp(i)
          })
        }
        // eslint-disable-next-line no-await-in-loop
        await this.savePdf(this.keepIPD_base64, typeFile)
        this.setImageBeforGet()
        console.log(index)
      }
      this.disabled_IPD = false
    },
    setImageBeforGet() {
      this.ImageIPD = []
      this.keepIPD_base64 = []
    },
    testKepp(x) {
      // console.log(x)
      let index = 0
      for (index; index < this.ImageIPD[x].length; index += 1) {
        this.keepIPD_base64.push(`data:image/png;base64,${this.ImageIPD[x][index].imageBase64}`)
      }
    },
    async savePdf(x, typeFile) {
      console.log('await this.savePdf(this.keepIPD_base64)', typeFile)
      const multiPng = await this.generatePdf(x)

      // generate dataURLString
      // const dataURLString = multiPng.output(`'dataurlstring', 'HN_${this.MRDoc[0].patientID}_MR.pdf'`)
      // console.log(dataURLString)

      // save PDF (blocked in iFrame in chrome)
      if (typeFile === 'mr') {
        multiPng.output('save', `HN_${this.MRDoc[0].patientID}_MR.pdf`)
      } else {
        multiPng.output('save', `HN_${this.MRDoc[0].patientID}_IPD.pdf`)
      }
      this.disabled_MR = false
    },
    async generatePdf(x) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'mm', 'a4')
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, url] of x.entries()) {
        // eslint-disable-next-line no-await-in-loop
        const image = await this.addImageProcess(url)
        doc.addImage(image, 'JPEG', 0, 0, width, height)
        if (i !== x.length - 1) {
          doc.addPage()
        }
      }
      return doc
    },
    async addImageProcess(src) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => resolve(img)
        img.onerror = reject
      })
    },
  },
}
</script>
<style lang="scss">
@media print {
  [dir] .vx-card.card-border {
    border: 1px solid #ffffff;
  }
  .vx-card__body {
    max-width: 100%;
  }
  .vs-sidebar {
    max-width: 1% !important;
    visibility: hidden;
  }
  .the-footer {
    max-width: 5% !important;
    visibility: hidden;
  }
  .vx-navbar-wrapper {
    max-width: 5% !important;
    visibility: hidden;
  }
  .mb-base {
    display: none !important;
  }
  .vx-card__collapsible-content {
    max-width: 100% !important;
    // display: none !important;
  }
  .vx-card {
    display: none !important;
  }
  .vs-button {
    display: none !important;
  }
}

@page {
  size: A4;
  margin-top: -4.2cm;
  margin-left: -2.8cm;
  margin-bottom: -50cm;
}
</style>
